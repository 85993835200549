<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_sector')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>

                    <v-text-field class="mt-3"
                            v-model="editedData.sectorName"
                            :error-messages="sectorNameErrors"
                            :label="$t('sector_name')"
                            required
                                  dense
                                              outlined
                            @input="$v.editedData.sectorName.$touch()"
                            @blur="$v.editedData.sectorName.$touch()"
                    ></v-text-field>

                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "sectorEdit",
        props: ['serviceID'],
        computed: {
            sectorNameErrors() {
                const errors = [];
                if (!this.$v.editedData.sectorName.$dirty) return errors;
                !this.$v.editedData.sectorName.required &&
                    errors.push(`${this.$t("sector_name")} ${this.$t("is_required")}`);
                return errors;
            },
        },
        data() {
            return {
                editedData: {},
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
            editedData: {
                sectorName: {required},
            }
        },
        mounted() {
            this.formData();
        },
        methods: {
            async formData() {
                const {data} = await axios.get('NRBExchange/GetSectorByIDAsync/' + this.serviceID)
                this.editedData = data
            },
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    axios.post("NRBExchange/UpdateSectorAsync", this.editedData).then(response => {
                        if (response.data.success) {
                            this.$v.$reset();
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Sector updated Successfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Updating Sector, Please Contact Admin"
                        this.servicesData.color = "error"
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData = {}
            }
        }
    };
</script>

<style scoped>
</style>